import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// This custom hook will be used to track the page view in the browser's history
const useTrackPageView = () => {
  const location = useLocation();
  useEffect(() => {
    const browserWindow = window as Window & typeof globalThis & { gtag?: any, hj: any };
    if (browserWindow.gtag) {
      browserWindow.gtag("event", "page_view", {
        page_path: location.pathname,
      });
    }

    if (typeof browserWindow !== "undefined" && browserWindow.hj) {
      browserWindow.hj("stateChange", location.pathname + location.search);
    }
  }, [location]);
  return null;
};

export default useTrackPageView;
